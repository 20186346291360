<template>
    <div>
        <p class="site-desc">
            Durch die momentane Situation auf den Energiemärkten stehen viele Verbraucher vor der Frage, welche
            Alternativen es z.B. zur klassischen Gastherme gibt.
            Die enviaM und das Institut für Energiemanagement der Hochschule Mittweida haben im Rahmen der
            Hochschulkooperation die momentanen Alternativen zum Heizen mit Gas verglichen.
        </p>
        <div class="view_overview">
            <FilterBarToggle/>
            <FilterBar/>
            <div class="tilesWrapper">
                <h2>Heizalternativen</h2>
                <p>Hier sehen Sie Ihre Alternativen zum Heizen mit Gas:</p>
                <div class="tiles">
                    <template v-for="c in contents">
                        <div v-if="(filter.livingSituation === 'all' || c.filter.livingSituation.includes(filter.livingSituation))
                            && (filter.energyType === 'all' || c.filter.energyType.includes(filter.energyType))
                            && (filter.location === 'all' || c.filter.location.includes(filter.location))
                            && (filter.purpose === 'all' || c.filter.purpose.includes(filter.purpose))
                            && (filter.cost === 'all' || c.filter.cost.includes(filter.cost))
                            && (filter.isolation === 'all' || c.filter.isolation.includes(filter.isolation))"
                             class="tile" @click="goto(c.id)">
                            <img class="tileImage" :src="'/content_images/' + c.image" :alt="c.image">
                            <div class="tileBanner"></div>
                            <div class="tileText">{{ c.name }}</div>
                        </div>
                        <div v-else class="tile-disabled">
                            <img class="tileImage" :src="'/content_images/' + c.image" :alt="c.image">
                            <div class="tileBanner"></div>
                            <div class="tileText">{{ c.name }}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import FilterBar from "@/components/FilterBar";
    import FilterBarToggle from "@/components/FilterBarToogle";

    export default {
        name: 'Overview',
        components: {FilterBarToggle, FilterBar},
        computed: {
            ...mapState(["contents", "filter"])
        },
        methods: {
            goto(id) {
                this.$router.push('/content_images/' + id);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/css/colors.scss";

    .site-desc {
        margin: 30px 0;
        hyphens: none;
    }

    .view_overview {
        display: flex;

        .tilesWrapper {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 12px 0;
            align-items: center;

            .tiles {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 1px;

                .tile, .tile-disabled {
                    background-color: $grey_light;
                    position: relative;
                    width: 175px;
                    height: 175px;
                    margin: 8px 1px;
                }

                .tile-disabled {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .tile {
                    cursor: pointer;
                    transition: all 0.25s;

                    &:hover {
                        opacity: 0.9;
                        transform: scale(1.03);
                    }
                }

                .tileImage {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }

                .tileText {
                    position: absolute;
                    left: 4px;
                    bottom: 6px;
                    font-size: 14px;
                }

                .tileBanner {
                    background-color: #eee;
                    opacity: 0.85;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 32px;
                    color: #fff;
                }
            }
        }
    }

    @media only screen and (min-width: 400px) {
        .tiles {
            padding: 0 12px !important;

            .tile, .tile-disabled {
                margin: 8px 8px !important;
            }
        }
    }
</style>
