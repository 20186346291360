<template>
    <div class="filterBar" :class="[filterBarIsOpened ? 'open' : '']">
        <h2 class="center">Filter</h2>
        <div class="filterBarContent">
            <fieldset>
                <legend>Wohnsituation</legend>
                <div>
                    <input id="w0" type="radio" v-model="filter.livingSituation" value="all"/>
                    <label for="w0">alle</label>
                </div>
                <div>
                    <input id="w1" type="radio" v-model="filter.livingSituation" value="flat"/>
                    <label for="w1">(Miet-)Wohnung</label>
                </div>
                <div>
                    <input id="w2" type="radio" v-model="filter.livingSituation" value="house"/>
                    <label for="w2">Eigenheim</label>
                </div>
            </fieldset>
            <fieldset>
                <legend>Heizungsart</legend>
                <div>
                    <input id="t0" type="radio" v-model="filter.purpose" value="all"/>
                    <label for="t0">alle</label>
                </div>
                <div>
                    <input id="t1" type="radio" v-model="filter.purpose" value="room"/>
                    <label for="t1">Raumheizung</label>
                </div>
                <div>
                    <input id="t2" type="radio" v-model="filter.purpose" value="water"/>
                    <label for="t2">Warmwasseraufbereitung</label>
                </div>
            </fieldset>
            <fieldset>
                <legend>Energieträger</legend>
                <div>
                    <input id="e0" type="radio" v-model="filter.energyType" value="all"/>
                    <label for="e0">alle</label>
                </div>
                <div>
                    <input id="e1" type="radio" v-model="filter.energyType" value="wood"/>
                    <label for="e1">Holz</label>
                </div>
                <div>
                    <input id="e2" type="radio" v-model="filter.energyType" value="electricity"/>
                    <label for="e2">Strom</label>
                </div>
                <div>
                    <input id="e3" type="radio" v-model="filter.energyType" value="environment"/>
                    <label for="e3">Umweltenergie</label>
                </div>
                <div>
                    <input id="e4" type="radio" v-model="filter.energyType" value="hydrogen"/>
                    <label for="e4">Wasserstoff</label>
                </div>
            </fieldset>
            <fieldset>
                <legend>Lage</legend>
                <div>
                    <input id="l0" type="radio" v-model="filter.location" value="all"/>
                    <label for="l0">alle</label>
                </div>
                <div>
                    <input id="l1" type="radio" v-model="filter.location" value="central"/>
                    <label for="l1">zentral</label>
                </div>
                <div>
                    <input id="l2" type="radio" v-model="filter.location" value="decentralized"/>
                    <label for="l2">dezentral</label>
                </div>
            </fieldset>
            <fieldset>
                <legend>Kosten</legend>
                <div>
                    <input id="cost0" type="radio" v-model="filter.cost" value="all"/>
                    <label for="cost0">alle</label>
                </div>
                <div>
                    <input id="cost1" type="radio" v-model="filter.cost" value="high"/>
                    <label for="cost1">hoch</label>
                </div>
                <div>
                    <input id="cost2" type="radio" v-model="filter.cost" value="low"/>
                    <label for="cost2">niedrig</label>
                </div>
            </fieldset>
            <fieldset>
                <legend>Dämmung</legend>
                <div>
                    <input id="iso0" type="radio" v-model="filter.isolation" value="all"/>
                    <label for="iso0">alle</label>
                </div>
                <div>
                    <input id="iso1" type="radio" v-model="filter.isolation" value="good"/>
                    <label for="iso1">gut</label>
                </div>
                <div>
                    <input id="iso2" type="radio" v-model="filter.isolation" value="bad"/>
                    <label for="iso2">schlecht</label>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'FilterBar',
        computed: {
            ...mapState(["contents", "filter", "filterBarIsOpened"]),
            filter: {
                get() {
                    return this.$store.state.filter;
                },
                set(value) {
                    this.$store.commit('filter', value);
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../css/colors";

    .filterBar {
        background-color: $grey_light;
        box-sizing: border-box;
        padding: 12px 0;
        z-index: 5;
        opacity: 0;
        transition: all 0.25s;
        width: 150px;
        position: absolute;
        left: -150px;
        top: 0;

        &.open {
            opacity: 1 !important;
            left: 0 !important;
            height: 100%;
        }

        .filterBarContent {
            display: flex;
            flex-direction: column;

            fieldset {
                display: flex;
                flex-direction: column;
                border: none;
                margin: 0 0 12px 0;

                & > div {
                    display: flex;
                    align-items: center;
                    line-height: 1.4;

                    input {
                        cursor: pointer;
                        padding: 0;
                        margin: 0 0 0 8px;
                    }

                    label {
                        padding: 0 0 0 6px;
                        margin: 0;
                        cursor: pointer;
                        font-size: 13px;
                        hyphens: auto;
                    }
                }

                legend {
                    font-size: 15px;
                }
            }
        }
    }

    @media only screen and (min-width: 750px) {
        .filterBar {
            flex: 0 0 150px;
            opacity: 1;
            transition: none;
            width: 150px;
            position: relative;
            left: 0;
            top: 0;
        }
    }
</style>
