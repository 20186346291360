<template>
    <button class="filterBarToogle" @click="filterBarIsOpened = !filterBarIsOpened">
        <i class="fa fa-filter"/>
    </button>
</template>

<script>
    export default {
        name: 'FilterBarToggle',
        computed: {
            filterBarIsOpened: {
                get() {
                    return this.$store.state.filterBarIsOpened;
                },
                set(value) {
                    this.$store.commit('filterBarIsOpened', value);
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../css/colors";

    .filterBarToogle {
        position: absolute;
        top: 8px;
        left: 4px;
        padding: 4px;
        color: $ifem_grey;
        background: none;
        border: none;
        z-index: 10;

        i {
            font-size: 16pt;
        }
    }

    @media only screen and (min-width: 750px) {
        .filterBarToogle {
            display: none !important;
        }
    }
</style>
